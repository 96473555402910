import React from 'react';
import { Element } from 'react-scroll';

const OurServices = () => {
    return (
        <React.Fragment>
            <Element id="ourservices" name="ourservices">
                <section className="section bg-color-white section-no-border section-height-3 my-0 appear-animation-visible animated fadeIn">
                    <div className="container">
                        <div className="row">
                            <div className="col text-center appear-animation-visible animated fadeInUpShorter" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="200">
                                <h2 className="font-weight-normal text-6 mb-5">Our <strong className="font-weight-extra-bold">Services</strong></h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 mb-4 mb-lg-0 appear-animation-visible animated fadeInRightShorter" style={{ animationDelay: '200ms' }}>
                                <div className="feature-box feature-box-style-4">
                                    <div className="feature-box-icon">
                                        <i className="icon-magnifier icons text-color-primary"></i>
                                    </div>
                                    <div className="feature-box-info">
                                        <h4 className="font-weight-bold text-color-dark mb-2">Literature & Systematic Reviews</h4>
                                        <p className="text-color-dark opacity-8 mb-0">PHSR Consulting supports the identification, selection, critical appraisal, quality assessment and analysis of the best available evidence on research questions in line with credible research guidelines on the conduct and reporting of literature and systematic reviews such as CRD, COCHRANE, PRISMA.  </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 mb-4 mb-lg-0 appear-animation-visible animated fadeInRightShorter" style={{ animationDelay: '400ms' }}>
                                <div className="feature-box feature-box-style-4">
                                    <div className="feature-box-icon">
                                        <i className="icon-layers icons text-color-primary"></i>
                                    </div>
                                    <div className="feature-box-info">
                                        <h4 className="font-weight-bold text-color-dark mb-2">Health Technology Assessment</h4>
                                        <p className="text-color-dark opacity-8 mb-0">We support the conduct and execution of HTAs across geographies including both the clinical and cost effectiveness evidence to inform dossier development. The HTAs are developed in line with the country requirements of reimbursement bodies such as NICE, AIFA, ANSM, AMNOG, etc. 
</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 mb-4">
                                <div className="feature-box feature-box-style-4 appear-animation-visible animated fadeInRightShorter" style={{ animationDelay: '500ms' }}>
                                    <div className="feature-box-icon">
                                        <i className="icon-eyeglass icons text-color-primary"></i>
                                    </div>
                                    <div className="feature-box-info">
                                        <h4 className="font-weight-bold text-color-dark mb-2">Real-World Evidence Studies</h4>
                                        <p className="text-color-dark opacity-8 mb-0">We plan, design and execute Real-World Evidence (RWE) studies through observational research relative to a range of study designs i.e. chart reviews, database analyses, PROs, etc. Real world evidence is generated from evidence sources beyond clinical trials and thus, is increasing being recognised as a major component to inform reimbursement decisions across therapeutic areas. At PHSR Consulting, we aim to ensure RWE is incorporated in the respective services we offer including HTAs, NMA, reviews, etc. 
</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-lg-4">
                            <div className="col-lg-4 mb-4 mb-lg-0">
                                <div className="feature-box feature-box-style-4 appear-animation-visible animated fadeInRightShorter" style={{ animationDelay: '500ms' }}>
                                    <div className="feature-box-icon">
                                        <i className="icon-graph icons text-color-primary"></i>
                                    </div>
                                    <div className="feature-box-info">
                                        <h4 className="font-weight-bold text-color-dark mb-2">Network Meta-analysis/ITC/MAIC</h4>
                                        <p className="text-color-dark opacity-8 mb-0">We use these techniques to compare multiple treatments simultaneously in a single analysis by combining direct and indirect evidence within a network of randomised controlled trials as well as real-world estimates, where applicable, based on common comparator(s). Information generated from pooled analyses can inform GEVD development, HTA submissions and facilitate decision making on product strategies.
</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 mb-4 mb-lg-0">
                                <div className="feature-box feature-box-style-4 appear-animation-visible animated fadeInLeftShorter" style={{ animationDelay: '600ms' }}>
                                    <div className="feature-box-icon">
                                        <i className="icon-eyeglass icons text-color-primary"></i>
                                    </div>
                                    <div className="feature-box-info">
                                        <h4 className="font-weight-bold text-color-dark mb-2">Consultancy and project management</h4>
                                        <p className="text-color-dark opacity-8 mb-0">In line with our client’s business needs, we provide our services on a consultancy basis and tailor level of interaction depending on the ask. Additionally, we support project management including cross-functional alignment with both internal and external stakeholders to support the execution of the project at hand. The level of support is dependent on the complexity and urgency of the request and we ensure to work with the client to support timely execution of the project. </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 mb-4 mb-lg-0">
                                <div className="feature-box feature-box-style-4 appear-animation-visible animated fadeInLeftShorter" style={{ animationDelay: '700ms' }}>
                                    <div className="feature-box-icon">
                                        <i className="icon-speedometer icons text-color-primary"></i>
                                    </div>
                                    <div className="feature-box-info">
                                        <h4 className="font-weight-bold text-color-dark mb-2">Guidelines</h4>
                                        <p className="text-color-dark opacity-8 mb-0">Using both published and unpublished evidence, we support the conduct and delivery of local, national and international guidelines across therapeutic disease areas in line with our client’s business needs. We also support the publication of these guidelines to inform clinical and policy making decisions. Our publication section showcases the guidelines we have been involved in.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-lg-4">
                            <div className="col-lg-4 mb-4 mb-lg-0">
                                <div className="feature-box feature-box-style-4 appear-animation-visible animated fadeInLeftShorter" style={{ animationDelay: '700ms' }}>
                                    <div className="feature-box-icon">
                                        <i className="icon-notebook icons text-color-primary"></i>
                                    </div>
                                    <div className="feature-box-info">
                                        <h4 className="font-weight-bold text-color-dark mb-2">Training</h4>
                                        <p className="text-color-dark opacity-8 mb-0">We offer and tailor training services to suit business needs such as short cases on literature searches, critical appraisal, methodological conduct of HTAs, design and set up of RWE studies and medical writing. </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 mb-4 mb-lg-0">
                                <div className="feature-box feature-box-style-4 appear-animation-visible animated fadeInLeftShorter" style={{ animationDelay: '700ms' }}>
                                    <div className="feature-box-icon">
                                        <i className="icon-notebook icons text-color-primary"></i>
                                    </div>
                                    <div className="feature-box-info">
                                        <h4 className="font-weight-bold text-color-dark mb-2">Medical Writing, QC and Publication support</h4>
                                        <p className="text-color-dark opacity-8 mb-0">Any scientific report or document should be composed in a clear, concise, and compliant way conveying the scientific information without any ambiguity and misrepresentation. We ensure that the documents related to clinical, non-clinical and other medical affairs related activities are aligned with industry standards. We support the writing and submission of publications (abstract/posters/manuscripts/letters/notes) to meet journal/congress/conference guidelines. Additionally, we advise on target journals to meet the methodologies of the studies at hand. 
</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 mb-4 mb-lg-0">
                                <div className="feature-box feature-box-style-4 appear-animation-visible animated fadeInLeftShorter" style={{ animationDelay: '700ms' }}>
                                    <div className="feature-box-icon">
                                        <i className="icon-notebook icons text-color-primary"></i>
                                    </div>
                                    <div className="feature-box-info">
                                        <h4 className="font-weight-bold text-color-dark mb-2">Regulatory Support</h4>
                                        <p className="text-color-dark opacity-8 mb-0">We support regulatory activities such as drafting and coordinating Orphan drug application submission (ODD/ODAs) and Risk Management Plans (RMPs), both core and across targeted markets in line with advice from regulatory bodies such as FDA, MHRA and EMA. Furthermore, we support the design, set up and conduct PASS/PAES to inform regulatory discussions and commitments to meet business needs. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                </section>
            </Element>
        </React.Fragment>
    )
};

export default OurServices;
