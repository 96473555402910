import React from 'react';
import { Element } from 'react-scroll';

const AboutUs = () => {
    return (
        <React.Fragment>
            <Element id="aboutus" name="aboutus">
                <section className="section bg-color-grey-scale-1 section-no-border section-height-3 my-0 appear-animation-visible animated fadeIn">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5 appear-animation-visible animation fadeInRightShorter" style={{ animationDelay: '300' }}>
                                {/* <span className="lead text-color-dark opacity-6 text-6 mb-0">LOREM IPSUM DOLOR SIT</span> */}
                                <h2 className="font-weight-normal line-height-1 text-6 negative-ls-1 mb-3">Who <strong className="font-weight-extra-bold">We Are</strong></h2>
                                <p className="text-color-dark opacity-8 text-4 mb-4">PHSR Consulting Services LTD was founded over 7 years ago by its Managing Director, Doreen A Kahangire.</p>
                                <p className="text-color-dark opacity-8 mb-4">
                                    PHSR Consulting specializes in undertaking systematic literature/targeted reviews, health technology assessment, data pooling/meta-analyses (NMA/MAIC/ITC) as well as Real World Evidence (RWE) studies to inform clinical and decision making across a range of therapeutic areas including, but not limited to, Bone, Cardiovascular, Inflammation, Neurology, Nephrology and Oncology. Furthermore, we support research related projects that fall under a regulatory remit/obligation.
                                </p>
                                {/* <a href="#" className="btn btn-dark font-weight-semibold btn-px-5 btn-py-2 text-2 rounded-0">LEARN MORE</a> */}
                            </div>
                            <div className="col-lg-7 mt-5 mt-lg-0">
                                <div className="row">
                                    <div className="col-lg-6 mb-4 mb-lg-5 appear-animation-visible animated fadeInRightShorter" style={{ animationDelay: '200ms' }}>
                                        <div className="feature-box feature-box-style-2">
                                            <div className="feature-box-icon">
                                                <i className="icon-star icons text-color-primary"></i>
                                            </div>
                                            <div className="feature-box-info">
                                                <h4 className="font-weight-bold text-color-dark mb-2">Vision</h4>
                                                <p className="text-color-dark opacity-8 mb-0">
                                                    Strive for excellence in scientific research through continued partnerships with Industry, Academia, Patients, Physicians and Policy Makers.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 mb-4 mb-lg-5 appear-animation-visible animated fadeInRightShorter" style={{ animationDelay: '400ms' }}>
                                        <div className="feature-box feature-box-style-2">
                                            <div className="feature-box-icon">
                                                <i className="icon-star icons text-color-primary"></i>
                                            </div>
                                            <div className="feature-box-info">
                                                <h4 className="font-weight-bold text-color-dark mb-2">Mission</h4>
                                                <p className="text-color-dark opacity-8 mb-0">To influence science-based research and Innovation across therapeutic areas.
</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-6 mb-4 mb-lg-5">
                                        <div className="feature-box feature-box-style-2 appear-animation-visible animated fadeInRightShorter" style={{ animationDelay: '500ms' }}>
                                            <div className="feature-box-icon">
                                                <i className="icon-star icons text-color-primary"></i>
                                            </div>
                                            <div className="feature-box-info">
                                                <h4 className="font-weight-bold text-color-dark mb-2">Values</h4>
                                                <p className="text-color-dark opacity-8 mb-0">Ethical, Team work, Communication and Accountability.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >
            </Element>
        </React.Fragment>
    )
};

export default AboutUs;
