
import React from 'react';
import { Element } from 'react-scroll';

const StatTile = ({ children }) => (
    <div className="col-sm-6 col-lg-4 mb-3 mb-lg-0 appear-animation animated fadeInDownShorter" data-appear-animation="fadeInDownShorter">
        {children}
    </div>
);

const CompanyStats = () => {
    return (
        <React.Fragment>
            <Element id="experience" name="experience">
                <section className="section section-no-border section-height-3 bg-color-primary my-0" >
                    <div className="container">
                        <div className="row counters counters-sm text-light">
                            <StatTile>
                                <div className="counter">
                                    <strong data-to="18000" data-append="+">10+</strong>
                                    <label className="text-light opacity-6 font-weight-normal pt-1">Years of Experience</label>
                                </div>
                            </StatTile>
                            <StatTile>
                                <div className="counter">
                                    <strong data-to="3500" data-append="+">20+</strong>
                                    <label className="text-light opacity-6 font-weight-normal pt-1">Publications</label>
                                </div>
                            </StatTile>
                            <StatTile>
                                <div className="counter">
                                    <strong data-to="16">60+</strong>
                                    <label className="text-light opacity-6 font-weight-normal pt-1">Literature & Systematic Reviews</label>
                                </div>
                            </StatTile>
                            {/* <StatTile>
                            <div className="counter">
                                <strong data-to="3000" data-append="+">0</strong>
                                <label className="text-light opacity-6 font-weight-normal pt-1">Development Hours</label>
                            </div>
                        </StatTile> */}
                        </div>
                    </div>
                </section>
            </Element>
        </React.Fragment>
    );
};

export default CompanyStats;