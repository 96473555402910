import React from 'react';
import { useStaticQuery } from 'gatsby';
import moment from 'moment';
import { Element } from 'react-scroll';
import PublicationTile from './publication-tile';

const FeaturedPublications = () => {

    const data = useStaticQuery(graphql`
    {
      allMdx(filter: {frontmatter: {published: {eq: true}}}, sort: {fields: frontmatter___date, order: DESC}) {
        nodes {
          excerpt(pruneLength: 250)
          frontmatter {
            title
            date
            pdfPath
          }
          fields {
            slug
          }
        }
      }
    }
  `);

    return (
        <React.Fragment>
            <Element id="publications" name="publications">
                <section className="section section-height-3 bg-color-grey-scale-1 section-no-border m-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 text-center">
                                <h2
                                    className="font-weight-normal text-6 mb-5">
                                    Our <strong className="font-weight-extra-bold">Publications</strong>
                                </h2>
                            </div>
                        </div>
                        <div className="row recent-posts pb-4 mb-5">
                            {data.allMdx.nodes.map(({ excerpt, frontmatter, fields }, index) => (
                                <div key={index} className="col-4 mb-4 mb-lg-0">
                                    <PublicationTile
                                        day={moment(frontmatter.date).format('DD')}
                                        month={moment(frontmatter.date).format('MMM').toUpperCase()}
                                        year={moment(frontmatter.date).format('YYYY').toUpperCase()}
                                        title={frontmatter.title}
                                        excerpt={excerpt}
                                        path={frontmatter.pdfPath}
                                    />
                                </div>

                            ))}
                        </div>
                    </div>
                </section>
            </Element>
        </React.Fragment>
    )
}

export default FeaturedPublications;