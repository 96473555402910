import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import FeaturedPublications from "../components/featured-publications";
import CompanyStats from '../components/company-stats';
import AboutUs from '../components/about-us';
import OurServices from '../components/our-services';
import Slider from '../components/slider';

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Slider />
    <AboutUs />
    <OurServices />
    <CompanyStats />
    <FeaturedPublications />
  </Layout>
)

export default IndexPage
