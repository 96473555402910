
import React from 'react';
import { Element } from 'react-scroll';
import Scroll from 'react-scroll';
import styled from 'styled-components'

const ScrollLink = Scroll.Link;

const StyledScrollLink = styled(ScrollLink)`
  cursor: pointer;
`
const Slider = () => {
    return (
        <React.Fragment>
            <Element id="home" name="home">
                <div className="slider-container rev_slider_wrapper">
                    <div
                        id="revolutionSlider"
                        className="slider rev_slider"
                        style={{ height: '100%' }}
                    >
                        <ul
                            className="mb-lg-0 pl-0"
                            style={{ listStyle: 'none', height: '100%' }}
                        >
                            <li
                                data-transition="fade"
                                className="slide-overlay slide-overlay-level-8"
                                style={
                                    {
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        height: '100%',
                                        textAlign: 'center',
                                        alignItems: 'center'
                                    }
                                }
                            >
                                <div
                                    className="tp-caption text-color-light font-weight-normal animated fadeIn"
                                    style={{ animationDelay: '200ms' }}
                                >WELCOME TO</div>

                                <h1
                                    className="tp-caption font-weight-extra-bold text-color-light negative-ls-2 animated fadeIn"
                                    style={
                                        {
                                            animationDelay: '340ms'
                                        }
                                    }
                                    data-letterspacing="-1">PUBLIC HEALTH & SYSTEMATIC REVIEWS</h1>

                                {/* <div
                                    className="tp-caption font-weight-light text-center animated fadeIn"
                                    style={
                                        {
                                            animationDelay: '500ms',
                                            color: '#b5b5b5'
                                        }
                                    }
                                >Get Yours <strong>Done</strong> Today.</div> */}

                                <StyledScrollLink
                                    to="contactus"
                                    spy={true}
                                    smooth={true}
                                    duration={500}
                                    offset={-100}
                                    className="tp-caption btn btn-light-2 btn-outline font-weight-semibold animated fadeIn"
                                >GET STARTED NOW!</StyledScrollLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </Element>
        </React.Fragment>
    );
};

export default Slider;